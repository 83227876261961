import { DownOutlined } from "@ant-design/icons";
import {
  Col,
  Layout,
  Menu,
  Row,
  theme,
  Dropdown,
  Space,
  Button,
  Tag,
} from "antd";
import { Outlet } from "react-router-dom";
import useDefaultLayout from "./useDefaultLayout";

const { Header, Content, Footer } = Layout;

const DefaultLayout = () => {
  const { items, pathname, menuItems, userName, vendorInfo } =
    useDefaultLayout();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header>
        <Row>
          <Col flex={1}>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[pathname]}
              selectedKeys={[pathname]}
              items={items}
            />
          </Col>
          <Col>
            <Space size="large">
              <Tag color="#f50">الرصيد: {vendorInfo.balance}</Tag>
              <Tag color="#87d068">عدد الطلبات: {vendorInfo.orders_count}</Tag>
              <Dropdown menu={{ items: menuItems }}>
                <Button type="link">
                  <Space>
                    {userName}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: "50px 50px", background: colorBgContainer }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>Haader Business</Footer>
    </Layout>
  );
};

export default DefaultLayout;
