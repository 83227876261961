/* eslint-disable no-param-reassign */
import axios from "axios";
import { ROUTES } from "@/contstants";
import { localStorageKeys } from "../storage";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

instance.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem(localStorageKeys.token);
    if (token) {
      config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json";
    return config;
  },
  async (error: any) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line func-names
  async function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem(localStorageKeys.token);
      window.location.href = ROUTES.login;
    }
    return Promise.reject(error);
  }
);

export default instance;
