import { Table, Drawer, Button, Space, TreeSelect, Form } from "antd";
import OrderForm from "../OrderForm";
import useOrdersTable from "./useOrdersTable";

const OrdersTable = () => {
  const {
    columns,
    rows,
    orders,
    onPagination,
    loading,
    loadingAreas,
    openDrawer,
    onCloseDrawer,
    selectedOrder,
    openRequestDriverDrawer,
    toggleRequestDriverDrawer,
    areasOptions,
    onRequestDriver,
    isAllowedToRequestDriver,
  } = useOrdersTable();
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {Boolean(isAllowedToRequestDriver) && (
        <Button
          type="primary"
          onClick={toggleRequestDriverDrawer}
          loading={loadingAreas}
        >
          طلب طيار
        </Button>
      )}

      <Table
        dataSource={rows}
        columns={columns}
        onChange={onPagination}
        loading={loading}
        pagination={{
          pageSize: orders?.meta?.per_page,
          total: orders?.meta?.total,
        }}
      />
      <Drawer
        size="large"
        open={openDrawer}
        onClose={onCloseDrawer}
        placement="right"
      >
        <OrderForm isEdit order={selectedOrder} onCloseDrawer={onCloseDrawer} />
      </Drawer>
      <Drawer
        size="large"
        open={openRequestDriverDrawer}
        onClose={toggleRequestDriverDrawer}
        placement="right"
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form layout="vertical" onFinish={onRequestDriver}>
            <Form.Item
              label="اختار منطقة العميل"
              name="area_id"
              rules={[
                {
                  required: true,
                  message: "من فضلك اختار منطقة العميل",
                },
              ]}
            >
              <TreeSelect
                treeData={areasOptions}
                treeDefaultExpandAll
                style={{ width: "100%" }}
                placeholder="اختار منطقة العميل"
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                تاكيد
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Drawer>
    </Space>
  );
};

export default OrdersTable;
