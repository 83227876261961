import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";

if (import.meta.env.VITE_CURRENT_ENV === "production") {
  Sentry.init({
    dsn: "https://55c1f687012eb184e7458d35fe2c38be@sentry.haader.com/6",
    environment: import.meta.env.VITE_CURRENT_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
