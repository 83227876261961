import { Typography, Row, Col } from "antd";
import OrderForm from "../OrderForm";

const { Title } = Typography;

const CreateOrder = () => {
  return (
    <Row>
      <Col span={24}>
        <Title>اعمل طلب</Title>
      </Col>
      <Col span={24}>
        <OrderForm />
      </Col>
    </Row>
  );
};

export default CreateOrder;
