import axiosInstance from "@/services/http/axiosInstance";

export const getOrders = async ({
  page,
  status,
}: {
  page?: number;
  status?: string;
}) => {
  const currentFilter = status ? `&status=${status}` : "";
  const response = await axiosInstance.get(
    `/api/vendor/order?page=${page || 1}${currentFilter}`
  );
  return response.data;
};

export const createNewOrder = async ({
  order_number,
  customer_name,
  address,
  phone,
  driver_id,
  area_id,
}: {
  order_number: string;
  customer_name: string;
  address: string;
  phone: string;
  driver_id?: string;
  area_id: string;
}) => {
  const response = await axiosInstance.post("/api/vendor/order", {
    order_number,
    customer_name,
    address,
    phone,
    area_id: area_id.toString(),
    ...(driver_id && { driver_id }),
  });
  return response.data;
};

export const updateOrder = async ({
  order_number,
  customer_name,
  address,
  phone,
  order_id,
  driver_id,
  area_id,
}: {
  order_number: string;
  customer_name: string;
  address: string;
  phone: string;
  order_id: string;
  driver_id?: string;
  area_id: string;
}) => {
  const response = await axiosInstance.put(`/api/vendor/order/${order_id}`, {
    order_number,
    customer_name,
    address,
    phone,
    area_id,
    ...(driver_id && { driver_id }),
  });
  return response.data;
};

export const cancelOrder = async (id: string) => {
  const response = await axiosInstance.post("api/vendor/order/cancel", {
    id,
  });
  return response.data;
};

export const getDrivers = async () => {
  const response = await axiosInstance.get("/api/vendor/drivers");
  return response.data;
};

export const getAreas = async () => {
  const response = await axiosInstance.get("/api/areas");
  return response.data;
};

export const requestDriver = async (area_id: string) => {
  const response = await axiosInstance.post(
    "/api/vendor/order/request-driver",
    {
      area_id,
    }
  );
  return response.data;
};
