import { useLocation } from "react-router-dom";
import { useAuth } from "@/contexts";
import { ROUTES } from "::root/src/contstants";

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { token } = useAuth();
  const location = useLocation();
  if (!token && location.pathname !== ROUTES.login) {
    window.location.replace(ROUTES.login);
  } else if (
    token &&
    (location.pathname === ROUTES.login || location.pathname === ROUTES.home)
  ) {
    window.location.replace(ROUTES.allOrders);
    return null;
  }
  return children;
};
