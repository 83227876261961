import { createContext, useMemo, useContext } from "react";
import { useLocalStorage, localStorageKeys } from "@/services/storage";

// the current implementation is just getting the token from local storage and validate that the user is authenticated based on the token
// the whole authentication process is implemented in the other repo

interface AuthContextType {
  token: string;
}

const AuthContext = createContext<AuthContextType>({
  token: "",
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { storedValue: token } = useLocalStorage(localStorageKeys.token, "");

  const value = useMemo(
    () => ({
      token,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
