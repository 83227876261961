import { Input, Form, Select, DatePicker } from "antd";
import { TextInputProps } from "@/types";

const { Search } = Input;
const { RangePicker } = DatePicker;

const TextInput = ({
  name,
  label,
  rules,
  disabled,
  type,
  options,
}: TextInputProps) => {
  const inputType: { [index: string]: React.ReactNode } = {
    search: <Search disabled={disabled} />,
    text: <Input disabled={disabled} />,
    select: <Select disabled={disabled} options={options} />,
    rangepicker: <RangePicker style={{ width: "100%" }} disabled={disabled} />,
  };

  return (
    <Form.Item name={name} label={label} rules={rules}>
      {inputType[type]}
    </Form.Item>
  );
};

export default TextInput;
