import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { AuthProvider } from "@/contexts";
import router from "@/navigation/router";
import "./App.css";

function App() {
  return (
    <ConfigProvider direction="rtl">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
