import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { message } from "antd";
import { ROUTES } from "@/contstants";
import axiosInstance from "@/services/http/axiosInstance";
import { localStorageKeys, useLocalStorage } from "::root/src/services/storage";
import { getBalanceAndOrdersCount } from "./actions";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    <NavLink to={ROUTES.allOrders}>كل الطلبات</NavLink>,
    ROUTES.allOrders
  ),
  getItem(
    <NavLink to={ROUTES.createOrder}>اعمل طلب</NavLink>,
    ROUTES.createOrder
  ),
];

export default function useDefaultLayout() {
  const { storedValue } = useLocalStorage(localStorageKeys.user, "");
  const navigate = useNavigate();
  const [vendorInfo, setVendorInfo] = useState<{
    balance: number;
    orders_count: number;
  }>({ balance: 0, orders_count: 0 });

  const userName = JSON.parse(storedValue || "{}")?.name;

  const menuItems: MenuProps["items"] = [
    {
      key: "1",
      label: "تغيير الرقم السري",
      onClick: () => navigate(ROUTES.changePassword),
    },
    {
      key: "2",
      label: "تسجيل الخروج",
      danger: true,
      onClick: () => {
        axiosInstance.post("/api/auth/logout").then(() => {
          localStorage.clear();
          window.location.replace(ROUTES.login);
        });
      },
    },
  ];

  useEffect(() => {
    getBalanceAndOrdersCount()
      .then((data) => {
        setVendorInfo(data);
      })
      .catch(() => {
        setVendorInfo({ balance: 0, orders_count: 0 });
        message.error("حدث خطأ ما اثناء جلب بيانات الرصيد وعدد الطلبات");
      });
  }, []);

  const { pathname } = useLocation();
  return { items, pathname, menuItems, userName, vendorInfo };
}
