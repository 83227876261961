import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, message } from "antd";
import axiosInstance from "@/services/http/axiosInstance";
import { ROUTES } from "::root/src/contstants";

const ChangePasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const onFinish = (values: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    axiosInstance
      .post("/api/auth/change-password", {
        password: values.password,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword,
      })
      .then(async () => {
        setLoading(false);
        navigate(ROUTES.allOrders);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          messageApi.open({
            content: "اسم المستخدم او كلمة المرور غير صحيحة",
            type: "error",
          });
        } else if (err.response.status === 403) {
          messageApi.open({
            content: `غير مسموح ${err.response?.data?.message}`,
            type: "error",
          });
        } else {
          messageApi.open({
            content: "حدث خطأ ما، الرجاء اخبار احد المسؤولين",
            type: "error",
          });
        }
      });
  };
  return (
    <Row align="middle" justify="center" dir="rtl">
      {contextHolder}
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        style={{ minWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="كلمة المرور القديمة"
          name="password"
          rules={[
            { required: true, message: "برجاء ادخال كلمة المرور القديمة" },
            { min: 8, message: "كلمة المرور يجب ان تكون علي الاقل 8 احرف" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="كلمة المرور الجديدة"
          name="newPassword"
          rules={[
            { required: true, message: "برجاء ادخال رمز المرور الجديدة" },
            { min: 8, message: "كلمة المرور يجب ان تكون علي الاقل 8 احرف" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="تأكيد كلمة المرور الجديدة"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "برجاء ادخال رمز المرور الجديدة مرة اخري",
            },
            { min: 8, message: "كلمة المرور يجب ان تكون علي الاقل 8 احرف" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("كلمة المرور غير متطابقة"));
              },
            }),
          ]}
          dependencies={["newPassword"]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            تغيير كلمة المرور
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default ChangePasswordPage;
