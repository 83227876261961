import { Form, Button, TreeSelect } from "antd";
import { Order } from "::root/src/types/orders";
import { TextInput } from "@/components/atoms";
import useOrderForm from "./useOrderForm";

const OrderForm = ({
  isEdit,
  order,
  onCloseDrawer,
}: {
  isEdit?: boolean;
  order?: Order | null;
  onCloseDrawer?: () => void;
}) => {
  const {
    feilds,
    form,
    initialValues,
    createOrder,
    loading,
    isAllowedToChooseDriver,
    editOrder,
    drivers,
    areasOptions,
  } = useOrderForm({ order, onCloseDrawer });
  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      wrapperCol={{ span: 14 }}
      onFinish={isEdit ? editOrder : createOrder}
    >
      {feilds(isAllowedToChooseDriver, drivers).map((feild) => (
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...feild}
          key={feild.name}
        />
      ))}

      <Form.Item
        label="اختار منطقة العميل"
        name="area_id"
        rules={[
          {
            required: true,
            message: "من فضلك اختار منطقة العميل",
          },
        ]}
      >
        <TreeSelect
          treeData={areasOptions}
          treeDefaultExpandAll
          style={{ width: "100%" }}
          placeholder="اختار منطقة العميل"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {isEdit ? "تعديل" : "ارسال"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
