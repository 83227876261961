import { useEffect, useState } from "react";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/es/table";
import { TablePaginationConfig, Button, Space, message } from "antd";
import {
  EditOutlined,
  CloseOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { ModalButton } from "@components/atoms";
import { Order, OrdersResponse, Area } from "@/types/orders";
import { getOrders, cancelOrder, getAreas, requestDriver } from "../actions";
import { localStorageKeys, useLocalStorage } from "::root/src/services/storage";

interface DataType {
  key: string;
}

const useOrdersTable = () => {
  const { storedValue } = useLocalStorage(localStorageKeys.user, "{}");
  const isAllowedToRequestDriver =
    JSON.parse(storedValue).is_allow_to_request_driver;
  const [orders, setOrders] = useState<OrdersResponse>({
    data: [],
    meta: {
      total: 0,
      per_page: 0,
    },
  });
  const [areas, setAreas] = useState<Area[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openRequestDriverDrawer, setOpenRequestDriverDrawer] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAreas, setLoadingAreas] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);

  const areasOptions = areas.map((area) => {
    return {
      title: area.name,
      value: area.id,
      disabled: true,
      children: area.zone.map((zone) => {
        return {
          title: zone.name,
          value: zone.id,
        };
      }),
    };
  });

  const getAllOrders = async ({
    page,
    status,
  }: {
    page?: number;
    status?: string;
  }) => {
    setLoading(true);
    const data = await getOrders({ page, status });
    setOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    getAllOrders({});
  }, []);

  const onPagination = ({ current }: TablePaginationConfig) => {
    getAllOrders({ page: current });
    setCurrentPage(current);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    getAllOrders({ page: currentPage });
  };

  const toggleRequestDriverDrawer = async () => {
    if (areas.length === 0) {
      setLoadingAreas(true);
      const data = await getAreas();
      setAreas(data);
      setLoadingAreas(false);
    }
    setOpenRequestDriverDrawer(!openRequestDriverDrawer);
  };

  const onRequestDriver = async (values: { area_id: string }) => {
    try {
      setLoading(true);
      await requestDriver(values.area_id);
      message.success("تم طلب طيار بنجاح");
      setLoading(false);
      toggleRequestDriverDrawer();
      getAllOrders({ page: currentPage });
    } catch (e) {
      setLoading(false);
      message.error("حدث خطأ ما");
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "العنوان",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "العميل",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      key: "phone",
      title: "هاتف العميل",
      dataIndex: "phone",
    },
    {
      key: "status",
      title: "الحالة",
      dataIndex: "status",
    },
    {
      key: "created_at",
      title: "اخر تعديل",
      render: (record: Order) => {
        return (
          <Space direction="vertical">
            {dayjs(record.updated_at).format("DD/MM/YYYY")}
            {dayjs(record.updated_at).format("HH:mm")}
          </Space>
        );
      },
    },
    {
      key: "driver",
      title: "السائق",
      dataIndex: "driver",
      render: (driver: { name: string }) => {
        return driver?.name;
      },
    },
    {
      key: "customer_location",
      title: "موقع العميل",
      render: (record: Order) => {
        return (
          record.drop_off_location && (
            <Button
              type="link"
              icon={<EnvironmentOutlined />}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${record.drop_off_location.latitude},${record.drop_off_location.longitude}`,
                  "_blank"
                );
              }}
            />
          )
        );
      },
    },
    {
      title: "العمليات",
      key: "action",
      render: (record: Order) => (
        <Space>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setOpenDrawer(true);
              setSelectedOrder(record);
            }}
          />
          {record.status === "pending" && (
            <ModalButton
              buttons={[
                {
                  title: "لا",
                  type: "default",
                  close: true,
                },
                {
                  title: "تاكيد",
                  type: "default",
                  danger: true,
                  loading,
                  onClick: async () => {
                    try {
                      setLoading(true);
                      await cancelOrder(record.id);
                      await getAllOrders({ page: currentPage });
                      message.success("تم الغاء الطلب بنجاح");
                      setLoading(false);
                    } catch (e) {
                      setLoading(false);
                      message.error("حدث خطأ ما");
                    }
                  },
                },
              ]}
              icon={<CloseOutlined />}
              dangerButton
              buttonType="primary"
              modalTitle="هل انت متاكد من الغاء الطلب"
            >
              الغاء الطلب # {record.order_number}
            </ModalButton>
          )}
        </Space>
      ),
    },
  ];

  const rows = orders?.data.map((order: Order) => {
    return {
      key: order.id,
      ...order,
    };
  });

  return {
    columns,
    rows,
    orders,
    onPagination,
    loading,
    loadingAreas,
    openDrawer,
    onCloseDrawer,
    selectedOrder,
    toggleRequestDriverDrawer,
    openRequestDriverDrawer,
    areasOptions,
    onRequestDriver,
    isAllowedToRequestDriver,
  };
};

export default useOrdersTable;
