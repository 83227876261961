import { Button, Modal, Row, Space } from "antd";
import { ModalButtonsProps } from "@/types";
import useMediaButton from "./useMediaButton";

type ModalButtonProps = {
  buttonLabel?: React.ReactNode;
  icon?: React.ReactNode;
  modalTitle: React.ReactNode;
  children: React.ReactNode;
  buttons: ModalButtonsProps[];
  buttonType: "primary" | "default" | "link" | "text" | "ghost";
  dangerButton: boolean;
};

const ModalButton = ({
  buttonLabel,
  icon,
  modalTitle,
  children,
  buttons,
  buttonType,
  dangerButton,
}: ModalButtonProps) => {
  const { showModal, isModalOpen, handleButtonClicked } = useMediaButton();
  return (
    <>
      <Button
        type={buttonType}
        onClick={showModal}
        danger={dangerButton}
        icon={icon}
      >
        {buttonLabel}
      </Button>
      <Modal
        open={isModalOpen}
        title={modalTitle}
        centered
        bodyStyle={{ minHeight: "50px", padding: 20 }}
        footer={
          <Row justify="center">
            <Space size="large" direction="horizontal" wrap>
              {buttons.map(
                ({ title, onClick, type, close, danger, loading }) => (
                  <Button
                    key={title}
                    type={type}
                    onClick={
                      close
                        ? handleButtonClicked
                        : async (e: any) => {
                            await onClick?.(e);
                            handleButtonClicked();
                          }
                    }
                    danger={danger}
                    loading={loading}
                  >
                    {title}
                  </Button>
                )
              )}
            </Space>
          </Row>
        }
        closable={false}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalButton;
