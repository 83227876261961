import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { TextInputProps } from "@/types";
import { ROUTES } from "@/contstants";
import { createNewOrder, updateOrder, getDrivers, getAreas } from "./actions";
import { localStorageKeys, useLocalStorage } from "::root/src/services/storage";
import { Area, Order } from "::root/src/types/orders";

const feilds = (
  canChooseDriver: boolean,
  drivers: { label: string; value: string }[]
): TextInputProps[] => [
  {
    name: "order_number",
    label: "رقم الاوردر",
    type: "text",
    rules: [
      {
        required: true,
        message: "يجب ادخال رقم الاوردر",
      },
    ],
  },
  {
    name: "customer_name",
    label: "اسم العميل",
    type: "text",
    rules: [
      {
        required: true,
        message: "يجب ادخال اسم العميل",
      },
    ],
  },
  {
    name: "address",
    label: "العنوان",
    type: "text",
    rules: [
      {
        required: true,
        message: "يجب ادخال العنوان",
      },
    ],
  },
  {
    name: "phone",
    label: "رقم هاتف العميل",
    type: "text",
    rules: [
      {
        required: true,
        message: "يجب ادخال رقم هاتف العميل",
      },
    ],
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...(canChooseDriver
    ? [
        {
          name: "driver_id",
          label: "اختر السائق",
          type: "select",
          options: drivers,
          rules: [
            {
              required: false,
            },
          ],
        },
      ]
    : []),
];

const useOrderForm = ({
  order,
  onCloseDrawer,
}: {
  order?: Order | null;
  onCloseDrawer?: () => void;
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [areas, setAreas] = useState<Area[]>([]);
  const [drivers, setDrivers] = useState<{ label: string; value: string }[]>(
    []
  );
  const { storedValue } = useLocalStorage(localStorageKeys.user, "{}");

  const isAllowedToChooseDriver =
    JSON.parse(storedValue).is_allow_to_choose_driver;

  const areasOptions = areas.map((area) => {
    return {
      title: area.name,
      value: area.id,
      disabled: true,
      children: area.zone.map((zone) => {
        return {
          title: zone.name,
          value: zone.id,
        };
      }),
    };
  });

  const initialValues = {
    order_number: order?.order_number || "",
    customer_name: order?.customer_name || "",
    address: order?.address || "",
    phone: order?.phone || "",
    driver_id: order?.driver?.id || "",
    area_id: order?.area?.area_id || "",
  };

  const createOrder = async (values: {
    order_number: string;
    customer_name: string;
    address: string;
    phone: string;
    driver_id?: string;
    area_id: string;
  }) => {
    try {
      setLoading(true);
      await createNewOrder(values);
      setLoading(false);
      message.success("تم اضافة الاوردر بنجاح");
      navigate(ROUTES.allOrders);
    } catch (error) {
      setLoading(false);
      message.error("حدث خطأ ما");
    }
  };

  const editOrder = async (values: {
    order_number: string;
    customer_name: string;
    order_id: string;
    address: string;
    phone: string;
    driver_id?: string;
    area_id: string;
  }) => {
    try {
      setLoading(true);
      await updateOrder({ ...values, order_id: order?.id || "" });
      onCloseDrawer?.();
      setLoading(false);
      message.success("تم تعديل الاوردر بنجاح");
      navigate(ROUTES.allOrders);
    } catch (error) {
      setLoading(false);
      message.error("حدث خطأ ما");
    }
  };

  useEffect(() => {
    if (order) {
      form.resetFields();
    }
    getAreas().then((res) => {
      setAreas(res);
    });

    if (isAllowedToChooseDriver) {
      getDrivers().then((res) => {
        setDrivers(
          res.data.map((driver: { name: string; id: string }) => ({
            label: driver.name,
            value: driver.id,
          }))
        );
      });
    }
  }, [order, areas.length, form, isAllowedToChooseDriver]);

  return {
    feilds,
    form,
    initialValues,
    createOrder,
    editOrder,
    loading,
    isAllowedToChooseDriver,
    drivers,
    areasOptions,
  };
};

export default useOrderForm;
