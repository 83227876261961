import { useState } from "react";

const useMediaButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleButtonClicked = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    showModal,
    handleButtonClicked,
  };
};

export default useMediaButton;
